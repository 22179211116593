<template>
    <div class="exhibitors">
        <Breadcrumb v-bind:item="title"/>
        <div class="lds-ring" v-if="isFetching">
            <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
        </div>
        <div v-else class="exhibitors-grid-wrap">
            <SectionHeader v-bind:title="title"/>

            <input v-on:input="search($event.target.value)" type="text" name="search" id="search" placeholder="SEARCH">
            <img class="icon-search" src="../assets/img/icon_search.svg">

            <div class="exhibitors-loaded">
                <div v-if="!isSearching" class="exhibitors-grid">
                    <div class="exhibitor" v-for="(exhibitor, item) in this.exhibitors" v-bind:key="item">
                        <img v-if="exhibitor.logo"  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.logo.fluid.src" alt="exhibitor">
                        <img v-else  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.mainImage.fluid.src" alt="exhibitor">

                        <h2 @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" class="exhibitor-title">{{ exhibitor.name }}</h2>

                        <p class="exhibitor-desc">{{shorten(exhibitor.shortDescription, 120, true)}}</p>
                        <a class="exhibitor-link" @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)">READ MORE</a>
                    </div>
                </div>

                <div v-else class="exhibitors-grid">
                    <div class="exhibitor" v-for="(exhibitor, item) in this.results" v-bind:key="item">
                        <img v-if="exhibitor.logo"  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.logo.fluid.src" alt="exhibitor">
                        <img v-else  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.mainImage.fluid.src" alt="exhibitor">

                        <h2 @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" class="exhibitor-title">{{ exhibitor.name }}</h2>

                        <p class="exhibitor-desc">{{shorten(exhibitor.shortDescription, 120, true)}}</p>
                        <a class="exhibitor-link" @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)">READ MORE</a>
                    </div>
                </div>

                <div v-if="!allLoaded && !isSearching" class="load-more">
                    <p @click="loadMore()">LOAD MORE</p>
                </div>

                <div v-if="!allLoadedSearch && isSearching" class="load-more">
                    <p @click="loadMoreSearch()">LOAD MORE</p>
                </div>

                <div v-if="noResults && allLoadedSearch" class="no-results">
                    <p>NO RESULTS FOUND</p>
                </div>

                <div class="pagination">
                    <a href="#" class="previous">previous</a>
                    <a href="#">&#60;&#60;</a>
                    <a href="#">&#60;</a>
                    <a class="active" href="#">1</a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#">5</a>
                    <a href="#">6</a>
                    <a href="#">&#62;</a>
                    <a href="#">&#62;&#62;</a>
                    <a href="#" class="next">next</a>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/snippets/Breadcrumb.vue'
import SectionHeader from '@/components/snippets/SectionHeader.vue'
import gql from 'graphql-tag'
import Fuse from 'fuse.js'

// const increment = 12


export default {
    name: 'Exhibitors',

    components: { 
        SectionHeader,
        Breadcrumb
    },

    data() {
        return {
            title: 'Importers',
            itemsPerPage: 12,
            itemsPerPageSearch: 12,
            isFetching: true,
            isSearching: false,
            fetched: false,
            searched: false,
            allLoaded: false,
            allLoadedSearch: false,
            noResults: false,
            exhibitors: [],
            exhibitorsAll: [],
            results: [],
            resultsAll: []
        }
    },

    methods: {
        goToExhibitor(slug, exhibitorId) {
            this.$router.push({name:'exhibitor', params:{slug:slug, exhibitorId:exhibitorId}})
        },
        shorten(str, n, useWordBoundary) {
            if (str.length <= n) { return str; }
            const subString = str.substr(0, n-1);
            return (useWordBoundary 
                ? subString.substr(0, subString.lastIndexOf(" ")) 
                : subString) + "..."
        },
        loadMore() {
            this.itemsPerPage += 12
            this.exhibitors = this.exhibitorsAll.slice(0, this.itemsPerPage)

            if (this.itemsPerPage >= this.exhibitorsAll.length) {
                this.allLoaded = true;
            } 
        },
        loadMoreSearch() {
            this.itemsPerPageSearch += 12
            this.results = this.resultsAll.slice(0, this.itemsPerPageSearch)

            if (this.itemsPerPageSearch >= this.resultsAll.length) {
                this.allLoadedSearch = true;
            }
        },
        search(string) {
            console.log("Searching");

            this.allLoadedSearch = false;
            this.$data.noResults = false

            if (string == "") {
                this.isSearching = false
                return false
            }

            var options;

            if (!isNaN(string)) {
                options = {
                    includeScore: true,
                    threshold: 0.0,
                    keys: [
                        'vintage'
                    ]
                }
            } else {
                options = {
                    includeScore: true,
                    threshold: 0.3,
                    keys: [
                        'name', 
                        {
                            name: 'city',
                            weight: 0.5
                        },
                        {
                            name: 'country',
                            weight: 0.5
                        },
                        {
                            name: 'shortDescription',
                            weight: 0.2
                        }
                    ]
                }
            }

            this.isSearching = true


            const fuse = new Fuse(this.$data.exhibitorsAll, options)
            const result = fuse.search(string)

            this.$data.results = []
            this.$data.resultsAll = []

            for (let i = 0; i < result.length; i++) {
                const obj = result[i];

                if (obj.item.bottleImage !== null) {
                    this.$data.resultsAll.push(obj.item)
                }
            }

            if (this.$data.resultsAll.length == 0) {
                this.$data.noResults = true
                this.$data.allLoadedSearch = true
            }

            if (this.$data.itemsPerPageSearch >= this.$data.resultsAll.length) {
                this.$data.allLoadedSearch = true;
            }

            this.$data.results = this.$data.resultsAll.splice(0, 12)
        
        }
    },


    apollo: {

        exhibitors: {
            query: gql`query {
                event(eventId: "5f842ed57507225ccb782eb4") {
                    exhibitors {
                        nodes {
                            mainImage {
                                fluid(maxWidth: 260, maxHeight:260, crop: FILL){
                                    src
                                }
                            }
                            logo {
                                fluid(maxWidth: 260, maxHeight:260, crop: PAD){
                                    src
                                }
                            }
                            name
                            shortDescription
                            city
                            country
                            slug
                            exhibitorId
                        }
                    }
                }
            }`,

            skip() {
                return this.fetched;
            },

            variables() {
                return {
                    itemsPerPage: this.itemsPerPage
                }
            },

            result ({ data, loading }) {

                if (!loading) {
                    var exhibArray = data.event.exhibitors.nodes

                    this.exhibitorsAll = exhibArray
                    this.exhibitors = exhibArray.slice(0,12)
                    this.isFetching = false
                    this.fetched = true
                }
            },
        }

    }
}
</script>